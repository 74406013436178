import React, { useEffect, useState, } from "react"
import PPCFooter from "../components/PPC/Footer"
import Faq from "../components/PPC/ReactDevPpc/Faq"
import Header from "../components/PPC/ReactDevPpc/Header"
import Seo from "../components/SEO"
import { useContextValues } from "../context/NavBarContext"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import BatchIcon from "../assets/img/batch-icon.inline.svg"
import StopwatchIcon from "../assets/img/stopwatch-icon.inline.svg"
import TimeWalletIcon from "../assets/img/time-wallet-icon.inline.svg"
import DownupArrowIcon from "../assets/img/downup-arrow-icon.inline.svg"

import ArrowWhite from "../assets/img/arrow-right.webp"
import SwiperSlider from "../components/SwiperSlider"
import { SwiperSlide } from "swiper/react"

const HireReactDevelopersUSA = () => {
  const { changeCurrentPath } = useContextValues()
  const [showModal, setShowModal] = useState(false)

  useEffect(() => changeCurrentPath(location.pathname), [])
  return (
    <>
      <Header setShowModal={setShowModal} />
      {/* build the team section */}
      <section className="py-20 md:py-24 lg:py-[6.875rem]">
        <div className="container">
          <div className="title-sec text-center md:pt-[6.875rem] pt-20 md:max-w-[47.5rem] m-auto md:pb-10 pb-9">
            <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium">Build your <em>Winning React Team</em> with ease and confidence<span className="inline-block text-orangecol">.</span></h2>
            <p className="inline-block py-2 px-6 rounded-[6.25rem] bg-[#E8E3FF]">Consider <span className="font-gorditamedium">Techuz as your pitcrew</span> by your side to win your product success race.</p>
          </div>

          <div className="flex gap-5 md:gap-6 md:gap-[1.938rem] justify-center">
            <div className="rounded-[1.25rem] border border-solid border-[#DFDCD3] overflow-hidden min-w-[14.375rem]">
              <figure className="bg-[#F8F7F3] pt-[1.625rem] px-10 flex items-end justify-center h-[12.313rem]">
                <StaticImage
                  className="block max-h-[10.688rem] mx-auto"
                  src="../assets/img/team-image.png"
                  alt="Team Image"
                />
              </figure>
              <div className="px-5 pt-[1.375rem] pb-[1.625rem]">
                <h4 className="text-lg font-gorditamedium leading-normal mb-[0.563rem]">Ashish Patel</h4>
                <span className="block text-sm leading-normal mb-1">Senior React Developer</span>
                <p className="italic text-xs leading-normal text-[#A0A0A0] font-gorditamedium">15+ yr exp.</p>
              </div>
            </div>
            <div className="rounded-[1.25rem] border border-solid border-[#DFDCD3] overflow-hidden min-w-[14.375rem]">
              <figure className="bg-[#F8F7F3] pt-[1.625rem] px-10 flex items-end justify-center h-[12.313rem]">
                <StaticImage
                  className="block max-h-[10.688rem] mx-auto"
                  src="../assets/img/team-image.png"
                  alt="Team Image"
                />
              </figure>
              <div className="px-5 pt-[1.375rem] pb-[1.625rem]">
                <h4 className="text-lg font-gorditamedium leading-normal mb-[0.563rem]">Nilesh Kadivar</h4>
                <span className="block text-sm leading-normal mb-1">Senior React Developer</span>
                <p className="italic text-xs leading-normal text-[#A0A0A0] font-gorditamedium">15+ yr exp.</p>
              </div>
            </div>
            <div className="rounded-[1.25rem] border border-solid border-[#DFDCD3] overflow-hidden min-w-[14.375rem]">
              <figure className="bg-[#F8F7F3] pt-[1.625rem] px-10 flex items-end justify-center h-[12.313rem]">
                <StaticImage
                  className="block max-h-[10.688rem] mx-auto"
                  src="../assets/img/team-image.png"
                  alt="Team Image"
                />
              </figure>
              <div className="px-5 pt-[1.375rem] pb-[1.625rem]">
                <h4 className="text-lg font-gorditamedium leading-normal mb-[0.563rem]">Mayank Mishra</h4>
                <span className="block text-sm leading-normal mb-1">Senior React Developer</span>
                <p className="italic text-xs leading-normal text-[#A0A0A0] font-gorditamedium">15+ yr exp.</p>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-[1.875rem] lg:gap-y-10 md:gap-x-10 lg:gap-x-[4.5rem] mt-10 md:mt-[3.125rem]">
            <div className="">
              <figure className="h-11 w-11 mb-5 md:mb-[1.875rem]">
                <BatchIcon />
              </figure>
              <span className="inline-block italic py-2 pb-[0.438rem] px-[1.125rem] md:px-6 rounded-[6.25rem] bg-[#E8E3FF] text-xs md:text-sm lg:text-base mb-2 md:mb-5 font-gorditamedium leading-[1.125rem] md:leading-[1.813rem]">Work With the Best  - Top 3%</span>
              <p className="text-[0.938rem] md:text-base leading-[1.625rem]">Work with the best-in-class engineers that provide true value to your project and business. With our meticulous recruitment process designed to hire just the best talents, we handpick the top 3 percent of applicants who stand upon our hiring standards of technical capabilities, communication, problem-solving abilities and behavioral skills.</p>
            </div>
            <div className="">
              <figure className="h-11 w-11 mb-5 md:mb-[1.875rem]">
                <StopwatchIcon />
              </figure>
              <span className="inline-block italic py-2 pb-[0.438rem] px-[1.125rem] md:px-6 rounded-[6.25rem] bg-[#FFF2E3] text-xs md:text-sm lg:text-base mb-2 md:mb-5 font-gorditamedium leading-[1.125rem] md:leading-[1.813rem]">Hire Fast - Within 48 hours</span>
              <p className="text-[0.938rem] md:text-base leading-[1.625rem]">Get the developers working on your project in as little as 48 hours. Just share your project requirements, select the developers who fit your technical and experience requirements, and get them on board. It’s that quick.</p>
            </div>
            <div className="">
              <figure className="h-11 w-11 mb-5 md:mb-[1.875rem]">
                <TimeWalletIcon />
              </figure>
              <span className="inline-block italic py-2 pb-[0.438rem] px-[1.125rem] md:px-6 rounded-[6.25rem] bg-[#E8FBFF] text-xs md:text-sm lg:text-base mb-2 md:mb-5 font-gorditamedium leading-[1.125rem] md:leading-[1.813rem]">Experience Transparency - Simple Monthly Billing</span>
              <p className="text-[0.938rem] md:text-base leading-[1.625rem]">We offer simple contracts with monthly billing based on man-hours. This means you only pay for the time spent on your project and also better control over your project budget. All the fully documented and transparent, giving you complete visibility into the work being done and the associated costs.</p>
            </div>
            <div className="">
              <figure className="h-11 w-11 mb-5 md:mb-[1.875rem]">
                <DownupArrowIcon />
              </figure>
              <span className="inline-block italic py-2 pb-[0.438rem] px-[1.125rem] md:px-6 rounded-[6.25rem] text-xs md:text-sm lg:text-base mb-2 md:mb-5 font-gorditamedium leading-[1.125rem] md:leading-[1.813rem]">Enjoy the flexibility -  Easy Scale Up/Down</span>
              <p className="text-[0.938rem] md:text-base leading-[1.625rem]">Stay agile and responsive to your project and business needs with easy scale-up or scale-down. Need more output during peak periods or want to reduce time to market? Add more developers. Did the workload decrease or want to cut the budget? This time, take off developers from your team.</p>
            </div>
          </div>
        </div>
      </section>

      {/* gradient featured section */}
      <section>
        <div className="container">
          <div className="flex flex-wrap border border-cream items-center justify-between gap-10 px-[1.875rem] pt-10 pb-[3.125rem] md:pt-[3.25rem] md:pb-[3.625rem] md:px-[4.375rem] md:bg-[url('../assets/img/tech-bg.webp')] bg-[url('../assets/img/tech-bg-mob.webp')] bg-center rounded-[1.25rem] overflow-hidden">
            <h2 className="font-blancomedium text-[1.75rem] md:text-3xl lg:text-4xl w-full md:w-[33.125rem] leading-[2.938rem]">You're <span className="text-[#4BD965] italic">just a step away</span> from building your winning team.</h2>
            <Link
              to=""
              className="inline-flex w-60 sm:w-auto gap-5 items-center bg-white-200 justify-center md:justify-start inline-white text-black border border-white shadow-1xl text-base rounded-3xl pl-7 py-1 pr-[0.313rem] font-gorditamedium hover:bg-primary hover:border-primary hover:text-white"
            >
              Get Started
              <span className="inline-flex w-[2.125rem] h-[2.125rem] bg-primary justify-center items-center rounded-full mr-auto md:mr-0">
                <img
                  src={ArrowWhite}
                  alt=""
                />
              </span>
            </Link>
          </div>
        </div>
      </section>

      {/* hiring developer section */}
      <section className="py-20 md:py-24 lg:py-[6.875rem] overflow-hidden">
        <div className="container">
          <div className="title-sec text-center md:max-w-[47.5rem] m-auto">
            <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium">What does <em>hiring top 3%</em> React <br className="max-lg:hidden" />developers  mean<span className="inline-block text-orangecol">.</span></h2>
            <p className="text-[0.938rem] md:text-base leading-[1.875rem]">At Techuz, we pride ourselves on selecting only the top 3% of the talent. With a proven four-step process, we ensure that we're selecting the best candidates with the skills and expertise needed to deliver exceptional results.</p>
          </div>

          <ul className="grid grid-cols-1 lg:grid-cols-4 gap-x-[1.688rem] gap-y-[1.875rem] mt-10 relative z-10 before:content-['*'] before:h-[0.063rem] before:absolute before:w-[500%] before:bg-black before:top-8 before:left-[50%] before:-translate-x-1/2 before:-z-10 max-lg:before:w-[0.063rem] max-lg:before:max-lg:h-full max-lg:before:top-0 max-lg:before:left-6 max-lg:before:translate-x-0">
            <li className="relative pl-16 lg:pl-0">
              <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-black rounded-[50%] flex items-center justify-center mb-5 bg-[#ffffff] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">1</span>
              <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-5">Screening candidates</h4>
              <span className="inline-block py-[0.438rem] px-[1.188rem] rounded-[6.25rem] bg-[#E8E3FF] text-xs md:text-sm lg:text-base mb-[0.875rem] md:mb-5 font-gorditaregular leading-[1.125rem] md:leading-[1.813rem]"><em className="font-gorditamedium">20%</em> of the candidates pass</span>
              <p className="text-[0.938rem] md:text-base leading-[1.625rem]">Stay agile and responsive to your project and business needs with easy scale-up or scale-down. Need more output during peak periods or want to reduce time to market? Add more developers. Did the workload decrease or want to cut the budget? This time, take off developers from your team.</p>
            </li>
            <li className="relative pl-16 lg:pl-0">
              <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-black rounded-[50%] flex items-center justify-center mb-5 bg-[#ffffff] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">2</span>
              <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-5">In-depth review & test</h4>
              <span className="inline-block py-[0.438rem] px-[1.188rem] rounded-[6.25rem] bg-[#FFF2E3] text-xs md:text-sm lg:text-base mb-[0.875rem] md:mb-5 font-gorditaregular leading-[1.125rem] md:leading-[1.813rem]"><em className="font-gorditamedium">7%</em> of the candidates pass</span>
              <p className="text-[0.938rem] md:text-base leading-[1.625rem]">Next, we conduct an in-depth skill review, communication and behavioral test to assess the candidate's communication skills, attitude, and ability to work effectively in a team environment.</p>
            </li>
            <li className="relative pl-16 lg:pl-0">
              <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-black rounded-[50%] flex items-center justify-center mb-5 bg-[#ffffff] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">3</span>
              <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-5">Live task</h4>
              <span className="inline-block py-[0.438rem] px-[1.188rem] rounded-[6.25rem] bg-[#E8FBFF] text-xs md:text-sm lg:text-base mb-[0.875rem] md:mb-5 font-gorditaregular leading-[1.125rem] md:leading-[1.813rem]"><em className="font-gorditamedium">3.3%</em> of the candidates pass</span>
              <p className="text-[0.938rem] md:text-base leading-[1.625rem]">Then we assign them test projects to evaluate their real-world skills, competence, and professionalism. This allows us to assess their ability to work under pressure, meet tight deadlines, and deliver high-quality work that meets our clients' needs.</p>
            </li>
            <li className="relative pl-16 lg:pl-0 max-lg:bg-[#ffffff] bg-opacity-100">
              <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-black rounded-[50%] flex items-center justify-center mb-5 bg-[#ffffff] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">4</span>
              <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-5">Consistent work</h4>
              <span className="inline-block py-[0.438rem] px-[1.188rem] rounded-[6.25rem] bg-[#E0FFEB] text-xs md:text-sm lg:text-base mb-[0.875rem] md:mb-5 font-gorditaregular leading-[1.125rem] md:leading-[1.813rem]"><em className="font-gorditamedium">3%</em> of the candidates pass</span>
              <p className="text-[0.938rem] md:text-base leading-[1.625rem]">Finally, with the selected candidates we ensure they provide consistent exemplary work of delivering outstanding results time and time again.</p>
            </li>
          </ul>
        </div>
      </section>

      {/* gradient featured section */}
      <section>
        <div className="container">
          <div className="flex flex-wrap border border-cream items-center justify-between gap-10 px-[1.875rem] pt-10 pb-[3.125rem] md:pt-[3.25rem] md:pb-[3.625rem] md:px-[4.375rem] md:bg-[url('../assets/img/tech-bg.webp')] bg-[url('../assets/img/tech-bg-mob.webp')] bg-center rounded-[1.25rem] overflow-hidden">
            <h2 className="font-blancomedium text-[1.75rem] md:text-3xl lg:text-4xl w-full md:w-[36.063rem] leading-[2.938rem]">
              Ready to <span className="text-[#4BD965] italic">accelerate your development</span> with the top 3% of React engineers?
            </h2>
            <Link
              to=""
              className="inline-flex w-60 sm:w-auto gap-5 items-center bg-white-200 justify-center md:justify-start inline-white text-black border border-white shadow-1xl text-base rounded-3xl pl-7 py-1 pr-[0.313rem] font-gorditamedium hover:bg-primary hover:border-primary hover:text-white"
            >
              Hire top engineers
              <span className="inline-flex w-[2.125rem] h-[2.125rem] bg-primary justify-center items-center rounded-full mr-auto md:mr-0">
                <img
                  src={ArrowWhite}
                  alt=""
                />
              </span>
            </Link>
          </div>
        </div>
      </section>

      {/* CTO section */}
      <section className="py-20 md:py-24 lg:py-[6.875rem]">
        <div className="container">
          <div className="title-sec text-center md:max-w-[47.5rem] mx-auto mb-[2.313rem] lg:mb-[4.375rem]">
            <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium">4 reasons <em>CTOs hire React developers </em> <br className="max-lg:hidden" />from Techuz<span className="inline-block text-orangecol">.</span></h2>
          </div>

          <div className="before:clear-both before:content-[''] before:block after:content-[''] after:block after:clear-both ">
            <div className="lg:float-left p-10 md:p-[3.125rem] bg-[#F8F7F2] rounded-[1.25rem] lg:max-w-[27.313rem] lg:sticky md:top-24 md:left-0">
              <p className="text-lg md:text-xl lg:text-[1.375rem] italic leading-[2.063rem]">“The engineers from Techuz can quickly digest information, allowing them to produce excellent code that meet highly technical requirements. Other highlights include phenomenal communication and project management. Their ability to adapt is impressive.”


                <span className="block not-italic mt-[1.875rem] text-lg font-gorditamedium text-base md:text-lg">Name, CTO - Company</span>
              </p>
            </div>
            <div className="lg:float-right lg:max-w-[calc(100%-27.313rem)] lg:pl-20">
              <div className="grid grid-cols-1 gap-y-[3.125rem] mt-10 md:mt-[3.125rem]">
                <div className="">
                  <figure className="h-11 w-11 mb-5 md:mb-[1.875rem]">
                    <BatchIcon />
                  </figure>
                  <span className="inline-block italic py-2 px-[1.125rem] md:px-6 rounded-[6.25rem] bg-[#E8E3FF] text-xs md:text-sm lg:text-base mb-2 md:mb-5 font-gorditamedium leading-[1.125rem] md:leading-[1.813rem]">Our engineers have extensive experience</span>
                  <p className="text-[0.938rem] md:text-base leading-[1.625rem]">90% of our React developers are senior and mind-senior level with extensive experience of working on different projects, teams, and tools. You don’t require any hand-holding, rather they proactively take care of your development goals, adding value to your project from day one.</p>
                </div>
                <div className="">
                  <figure className="h-11 w-11 mb-5 md:mb-[1.875rem]">
                    <BatchIcon />
                  </figure>
                  <span className="inline-block italic py-2 px-[1.125rem] md:px-6 rounded-[6.25rem] bg-[#FFF2E3] text-xs md:text-sm lg:text-base mb-2 md:mb-5 font-gorditamedium leading-[1.125rem] md:leading-[1.813rem]">Our engineers stay for long</span>
                  <p className="text-[0.938rem] md:text-base leading-[1.625rem]">Our commitment to long-term partnerships extends to both our clients and team members. With team members who have been with us since our inception, we have maintained a healthy employee turnover rate of 10 per cent. This provides our clients with the confidence to hire developers for extended projects and get seamless collaboration and consistent value.</p>
                </div>
                <div className="">
                  <figure className="h-11 w-11 mb-5 md:mb-[1.875rem]">
                    <BatchIcon />
                  </figure>
                  <span className="inline-block italic py-2 pb-[0.438rem] px-[1.125rem] md:px-6 rounded-[6.25rem] bg-[#E8FBFF] text-xs md:text-sm lg:text-base mb-2 md:mb-5 font-gorditamedium leading-[1.125rem] md:leading-[1.813rem]">Our engineers are problem solvers</span>
                  <p className="text-[0.938rem] md:text-base leading-[1.625rem]">We deeply believe that the very essence of programming is problem-solving and that’s reflected in our developers’ work. We take a step further to the technical aspect and consider the real-world problems, solutions,  and user experience. With a problem-solving mindset we take more thoughtful and strategic approach to development, resulting in better outcomes for our clients.</p>
                </div>
                <div className="">
                  <figure className="h-11 w-11 mb-5 md:mb-[1.875rem]">
                    <BatchIcon />
                  </figure>
                  <span className="inline-block italic py-2 px-[1.125rem] md:px-6 rounded-[6.25rem] bg-[#E0FFEB] text-xs md:text-sm lg:text-base mb-2 md:mb-5 font-gorditamedium leading-[1.125rem] md:leading-[1.813rem]">We prioritize learning & development</span>
                  <p className="text-[0.938rem] md:text-base leading-[1.625rem]">We constantly work to enhance our developers’ expertise so that they are capable of tackling even the most complex tasks. Additionally, we prioritize the personal growth of our developers, ensuring that they have the resources and support they need to reach their full potential. This directly translates into better outcomes for our clients, who get consistently high-quality work and innovative solutions from our team.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* gradient featured section */}
      <section>
        <div className="container">
          <div className="flex flex-wrap border border-cream items-center justify-between gap-10 px-[1.875rem] pt-10 pb-[3.125rem] md:pt-[3.25rem] md:pb-[3.625rem] md:px-[4.375rem] md:bg-[url('../assets/img/tech-bg.webp')] bg-[url('../assets/img/tech-bg-mob.webp')] bg-center rounded-[1.25rem] overflow-hidden">
            <h2 className="font-blancomedium text-[1.75rem] md:text-3xl lg:text-4xl w-full md:w-[29.188rem] leading-[2.938rem]">
              Hire React developers <span className="text-[#4BD965] italic">trusted by top CTOs</span><span className="inline-block text-orangecol">.</span>
            </h2>
            <Link
              to=""
              className="inline-flex w-60 sm:w-auto gap-5 items-center bg-white-200 justify-center md:justify-start inline-white text-black border border-white shadow-1xl text-base rounded-3xl pl-7 py-1 pr-[0.313rem] font-gorditamedium hover:bg-primary hover:border-primary hover:text-white"
            >
              Hire and supercharge your project
              <span className="inline-flex w-[2.125rem] h-[2.125rem] bg-primary justify-center items-center rounded-full mr-auto md:mr-0">
                <img
                  src={ArrowWhite}
                  alt=""
                />
              </span>
            </Link>
          </div>
        </div>
      </section>

      {/* hiring developer section */}
      <section className="py-10 md:py-12 lg:py-[3.438rem] overflow-hidden">
        <div className="container">
          <div className="title-sec text-center md:max-w-[47.5rem] m-auto">
            <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium">Our React developers are <em>well-versed</em> in <br className="max-lg:hidden" />the React ecosystem<span className="inline-block text-orangecol">.</span></h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-[3.125rem] gap-x-[6.875rem] mt-[3.25rem] lg:mt-[5rem]">
            <div className="flex flex-wrap items-start justify-start gap-x-[4.375rem]">
              <div className="mb-[4.25rem]">
                <h5 className="w-full text-base lg:text-lg font-gorditamedium leading-6 lg:leading-[1.813rem] mb-4 lg:mb-[1.25rem]">App setup</h5>
                <div className="img-section flex flex-wrap items-start gap-x-5 gap-y-[1.875rem]">
                  <div className="flex flex-col gap-[0.625rem]">
                    <figure className="py-[1.438rem] px-[1.375rem] bg-[#F8F7F2] rounded-[1.25rem] w-[5.625rem] h-[5.625rem] inline-flex mb-[0.625rem]">
                      <StaticImage
                        src="../assets/img/react-app.webp"
                        className="max-w-full object-contain"
                        alt="" />
                    </figure>
                    <span className="text-xs md:text-sm leading-4 md:leading-[1.188rem]">React App</span>
                  </div>
                </div>
              </div>
              <div className="mb-[4.25rem]">
                <h5 className="w-full text-base lg:text-lg font-gorditamedium leading-6 lg:leading-[1.813rem] mb-4 lg:mb-[1.25rem]">Routing</h5>
                <div className="img-section flex flex-wrap items-start gap-x-5 gap-y-[1.875rem]">
                  <div className="flex flex-col gap-[0.625rem]">
                    <figure className="py-[1.438rem] px-[1.375rem] bg-[#F8F7F2] rounded-[1.25rem] w-[5.625rem] h-[5.625rem] inline-flex mb-[0.625rem]">
                      <StaticImage
                        src="../assets/img/react-router.webp"
                        className="max-w-full object-contain"
                        alt="" />
                    </figure>
                    <span className="text-xs md:text-sm leading-4 md:leading-[1.188rem]">React Router</span>
                  </div>
                  <div className="flex flex-col gap-[0.625rem]">
                    <figure className="py-[1.438rem] px-[1.375rem] bg-[#F8F7F2] rounded-[1.25rem] w-[5.625rem] h-[5.625rem] inline-flex mb-[0.625rem]">
                      <StaticImage
                        src="../assets/img/react-router2.webp"
                        className="max-w-full object-contain"
                        alt="" />
                    </figure>
                    <span className="text-xs md:text-sm leading-4 md:leading-[1.188rem]">React Router</span>
                  </div>
                </div>
              </div>
              <div className="mb-[4.25rem]">
                <h5 className="w-full text-base lg:text-lg font-gorditamedium leading-6 lg:leading-[1.813rem] mb-4 lg:mb-[1.25rem]">State Management</h5>
                <div className="img-section flex flex-wrap items-start gap-x-5 gap-y-[1.875rem]">
                  <div className="flex flex-col gap-[0.625rem]">
                    <figure className="py-[1.438rem] px-[1.375rem] bg-[#F8F7F2] rounded-[1.25rem] w-[5.625rem] h-[5.625rem] inline-flex mb-[0.625rem]">
                      <StaticImage
                        src="../assets/img/redux.webp"
                        className="max-w-full object-contain"
                        alt="" />
                    </figure>
                    <span className="text-xs md:text-sm leading-4 md:leading-[1.188rem]">Redux</span>
                  </div>

                  <div className="flex flex-col gap-[0.625rem]">
                    <figure className="py-[1.438rem] px-[1.375rem] bg-[#F8F7F2] rounded-[1.25rem] w-[5.625rem] h-[5.625rem] inline-flex mb-[0.625rem]">
                      <StaticImage
                        src="../assets/img/redux.webp"
                        className="max-w-full object-contain"
                        alt="" />
                    </figure>
                    <span className="text-xs md:text-sm leading-4 md:leading-[1.188rem]">Redux thunk</span>
                  </div>

                  <div className="flex flex-col gap-[0.625rem]">
                    <figure className="py-[1.438rem] px-[1.375rem] bg-[#F8F7F2] rounded-[1.25rem] w-[5.625rem] h-[5.625rem] inline-flex mb-[0.625rem]">
                      <StaticImage
                        src="../assets/img/redux-saga.webp"
                        className="max-w-full object-contain"
                        alt="" />
                    </figure>
                    <span className="text-xs md:text-sm leading-4 md:leading-[1.188rem]">Redux Saga</span>
                  </div>

                  <div className="flex flex-col gap-[0.625rem]">
                    <figure className="py-[1.438rem] px-[1.375rem] bg-[#F8F7F2] rounded-[1.25rem] w-[5.625rem] h-[5.625rem] inline-flex mb-[0.625rem]">
                      <StaticImage
                        src="../assets/img/mobx.webp"
                        className="max-w-full object-contain"
                        alt="" />
                    </figure>
                    <span className="text-xs md:text-sm leading-4 md:leading-[1.188rem]">MobX</span>
                  </div>
                </div>
              </div>
              <div className="mb-[4.25rem]">
                <h5 className="w-full text-base lg:text-lg font-gorditamedium leading-6 lg:leading-[1.813rem] mb-4 lg:mb-[1.25rem]">UI components</h5>
                <div className="img-section flex flex-wrap items-start gap-x-5 gap-y-[1.875rem]">
                  <div className="flex flex-col gap-[0.625rem]">
                    <figure className="py-[1.438rem] px-[1.375rem] bg-[#F8F7F2] rounded-[1.25rem] w-[5.625rem] h-[5.625rem] inline-flex mb-[0.625rem]">
                      <StaticImage
                        src="../assets/img/material-ui.webp"
                        className="max-w-full object-contain"
                        alt="" />
                    </figure>
                    <span className="text-xs md:text-sm leading-4 md:leading-[1.188rem]">Material UI</span>
                  </div>
                  <div className="flex flex-col gap-[0.625rem]">
                    <figure className="py-[1.438rem] px-[1.375rem] bg-[#F8F7F2] rounded-[1.25rem] w-[5.625rem] h-[5.625rem] inline-flex mb-[0.625rem]">
                      <StaticImage
                        src="../assets/img/react-bootstrap.webp"
                        className="max-w-full object-contain"
                        alt="" />
                    </figure>
                    <span className="text-xs md:text-sm leading-4 md:leading-[1.188rem]">React Bootstrap</span>
                  </div>
                  <div className="flex flex-col gap-[0.625rem]">
                    <figure className="py-[1.438rem] px-[1.375rem] bg-[#F8F7F2] rounded-[1.25rem] w-[5.625rem] h-[5.625rem] inline-flex mb-[0.625rem]">
                      <StaticImage
                        src="../assets/img/reactstrap.webp"
                        className="max-w-full object-contain"
                        alt="" />
                    </figure>
                    <span className="text-xs md:text-sm leading-4 md:leading-[1.188rem]">Reactstrap</span>
                  </div>
                  <div className="flex flex-col gap-[0.625rem]">
                    <figure className="py-[1.438rem] px-[1.375rem] bg-[#F8F7F2] rounded-[1.25rem] w-[5.625rem] h-[5.625rem] inline-flex mb-[0.625rem]">
                      <StaticImage
                        src="../assets/img/primereact.webp"
                        className="max-w-full object-contain"
                        alt="" />
                    </figure>
                    <span className="text-xs md:text-sm leading-4 md:leading-[1.188rem]">Primereact</span>
                  </div>
                  <div className="flex flex-col gap-[0.625rem]">
                    <figure className="py-[1.438rem] px-[1.375rem] bg-[#F8F7F2] rounded-[1.25rem] w-[5.625rem] h-[5.625rem] inline-flex mb-[0.625rem]">
                      <StaticImage
                        src="../assets/img/chakra.webp"
                        className="max-w-full object-contain"
                        alt="" />
                    </figure>
                    <span className="text-xs md:text-sm leading-4 md:leading-[1.188rem]">Chakra UI</span>
                  </div>
                  <div className="flex flex-col gap-[0.625rem]">
                    <figure className="py-[1.438rem] px-[1.375rem] bg-[#F8F7F2] rounded-[1.25rem] w-[5.625rem] h-[5.625rem] inline-flex mb-[0.625rem]">
                      <StaticImage
                        src="../assets/img/art-design.webp"
                        className="max-w-full object-contain"
                        alt="" />
                    </figure>
                    <span className="text-xs md:text-sm leading-4 md:leading-[1.188rem]">Ant Design</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap items-start justify-start gap-x-[4.375rem]">
              <div className="mb-[4.25rem]">
                <h5 className="w-full text-base lg:text-lg font-gorditamedium leading-6 lg:leading-[1.813rem] mb-4 lg:mb-[1.25rem]">Framework</h5>
                <div className="img-section flex flex-wrap items-start gap-x-5 gap-y-[1.875rem]">
                  <div className="flex flex-col gap-[0.625rem]">
                    <figure className="py-[1.438rem] px-[1.375rem] bg-[#F8F7F2] rounded-[1.25rem] w-[5.625rem] h-[5.625rem] inline-flex mb-[0.625rem]">
                      <StaticImage
                        src="../assets/img/gatsby.webp"
                        className="max-w-full object-contain"
                        alt="" />
                    </figure>
                    <span className="text-xs md:text-sm leading-4 md:leading-[1.188rem]">Gatsby</span>
                  </div>
                </div>
              </div>
              <div className="mb-[4.25rem]">
                <h5 className="w-full text-base lg:text-lg font-gorditamedium leading-6 lg:leading-[1.813rem] mb-4 lg:mb-[1.25rem]">Testing</h5>
                <div className="img-section flex flex-wrap items-start gap-x-5 gap-y-[1.875rem]">
                  <div className="flex flex-col gap-[0.625rem]">
                    <figure className="py-[1.438rem] px-[1.375rem] bg-[#F8F7F2] rounded-[1.25rem] w-[5.625rem] h-[5.625rem] inline-flex mb-[0.625rem]">
                      <StaticImage
                        src="../assets/img/enzyme.webp"
                        className="max-w-full object-contain"
                        alt="" />
                    </figure>
                    <span className="text-xs md:text-sm leading-4 md:leading-[1.188rem]">Enzyme</span>
                  </div>
                  <div className="flex flex-col gap-[0.625rem]">
                    <figure className="py-[1.438rem] px-[1.375rem] bg-[#F8F7F2] rounded-[1.25rem] w-[5.625rem] h-[5.625rem] inline-flex mb-[0.625rem]">
                      <StaticImage
                        src="../assets/img/react-testing-library.webp"
                        className="max-w-full object-contain"
                        alt="" />
                    </figure>
                    <span className="text-xs md:text-sm leading-4 md:leading-[1.188rem]">React testing library</span>
                  </div>
                </div>
              </div>

              <div className="mb-[4.25rem]">
                <h5 className="w-full text-base lg:text-lg font-gorditamedium leading-6 lg:leading-[1.813rem] mb-4 lg:mb-[1.25rem]">Misc</h5>
                <div className="img-section flex flex-wrap items-start gap-x-5 gap-y-[1.875rem]">
                  <div className="flex flex-col gap-[0.625rem]">
                    <figure className="py-[1.438rem] px-[1.375rem] bg-[#F8F7F2] rounded-[1.25rem] w-[5.625rem] h-[5.625rem] inline-flex mb-[0.625rem]">
                      <StaticImage
                        src="../assets/img/format-js.webp"
                        className="max-w-full object-contain"
                        alt="" />
                    </figure>
                    <span className="text-xs md:text-sm leading-4 md:leading-[1.188rem]">Format js</span>
                  </div>

                  <div className="flex flex-col gap-[0.625rem]">
                    <figure className="py-[1.438rem] px-[1.375rem] bg-[#F8F7F2] rounded-[1.25rem] w-[5.625rem] h-[5.625rem] inline-flex mb-[0.625rem]">
                      <StaticImage
                        src="../assets/img/preact.webp"
                        className="max-w-full object-contain"
                        alt="" />
                    </figure>
                    <span className="text-xs md:text-sm leading-4 md:leading-[1.188rem]">Preact</span>
                  </div>

                  <div className="flex flex-col gap-[0.625rem]">
                    <figure className="py-[1.438rem] px-[1.375rem] bg-[#F8F7F2] rounded-[1.25rem] w-[5.625rem] h-[5.625rem] inline-flex mb-[0.625rem]">
                      <StaticImage
                        src="../assets/img/storybook.webp"
                        className="max-w-full object-contain"
                        alt="" />
                    </figure>
                    <span className="text-xs md:text-sm leading-4 md:leading-[1.188rem]">Storybook</span>
                  </div>

                  <div className="flex flex-col gap-[0.625rem]">
                    <figure className="py-[1.438rem] px-[1.375rem] bg-[#F8F7F2] rounded-[1.25rem] w-[5.625rem] h-[5.625rem] inline-flex mb-[0.625rem]">
                      <StaticImage
                        src="../assets/img/styled-components.webp"
                        className="max-w-full object-contain"
                        alt="" />
                    </figure>
                    <span className="text-xs md:text-sm leading-4 md:leading-[1.188rem]">Styled components</span>
                  </div>
                </div>
              </div>
              <div className="mb-[4.25rem]">
                <h5 className="w-full text-base lg:text-lg font-gorditamedium leading-6 lg:leading-[1.813rem] mb-4 lg:mb-[1.25rem]">Forms</h5>
                <div className="img-section flex flex-wrap items-start gap-x-5 gap-y-[1.875rem]">
                  <div className="flex flex-col gap-[0.625rem]">
                    <figure className="py-[1.438rem] px-[1.375rem] bg-[#F8F7F2] rounded-[1.25rem] w-[5.625rem] h-[5.625rem] inline-flex mb-[0.625rem]">
                      <StaticImage
                        src="../assets/img/react-hook-form.webp"
                        className="max-w-full object-contain"
                        alt="" />
                    </figure>
                    <span className="text-xs md:text-sm leading-4 md:leading-[1.188rem]">React hook form</span>
                  </div>
                  <div className="flex flex-col gap-[0.625rem]">
                    <figure className="py-[1.438rem] px-[1.375rem] bg-[#F8F7F2] rounded-[1.25rem] w-[5.625rem] h-[5.625rem] inline-flex mb-[0.625rem]">
                      <StaticImage
                        src="../assets/img/react-final-form.webp"
                        className="max-w-full object-contain"
                        alt="" />
                    </figure>
                    <span className="text-xs md:text-sm leading-4 md:leading-[1.188rem]">React final form</span>
                  </div>
                  <div className="flex flex-col gap-[0.625rem]">
                    <figure className="py-[1.438rem] px-[1.375rem] bg-[#F8F7F2] rounded-[1.25rem] w-[5.625rem] h-[5.625rem] inline-flex mb-[0.625rem]">
                      <StaticImage
                        src="../assets/img/formik.webp"
                        className="max-w-full object-contain"
                        alt="" />
                    </figure>
                    <span className="text-xs md:text-sm leading-4 md:leading-[1.188rem]">Formik</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* assemble your tem section */}
      <section className="py-10 md:py-12 lg:py-[3.438rem]">
        <div className="container">
          <div className="title-sec text-center md:max-w-[47.5rem] m-auto">
            <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium">Assemble your React team starting at an <br className="max-lg:hidden" /><span className="text-[#4BD965] italic">affordable price</span><span className="inline-block text-orangecol">.</span></h2>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-[1.875rem] mt-[3.125rem] text-center">
            <div className="py-12 px-[3.625rem] border-2 border-[#FFF2E3] rounded-[1.25rem]">
              <span className="inline-block italic py-2 px-[1.125rem] md:px-6 rounded-[6.25rem] bg-[#E8E3FF] text-xs md:text-sm lg:text-base mb-10 md:mb-[2.875rem] font-gorditamedium leading-[1.125rem] md:leading-[1.813rem]">Hire on an hourly basis</span>
              <div className="font-gorditamedium text-xl lg:text-2xl mb-[8.438rem]"><span className="text-[2.875rem] lg:text-[3.375rem]">$15</span>/Hour</div>

              <ul className="text-base text-black leading-[1.625] font-gorditaregular text-left">
                <li className="relative pl-7 mb-[0.625rem]">
                  <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-4 w-4 absolute top-[0.188rem] left-0"></span> Dedicated Pay only for hours worked
                </li>
                <li className="relative pl-7">
                  <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-4 w-4 absolute top-[0.188rem] left-0"></span> 8+ years of Ideal if looking for time and material model
                </li>
              </ul>
              <Link
                to=""
                className="inline-flex w-60 sm:w-auto gap-5 items-center bg-white-200 justify-center md:justify-start text-black border border-black shadow-1xl text-base rounded-3xl pl-7 py-1 pr-[0.313rem] font-gorditamedium hover:bg-primary hover:border-primary hover:text-white mt-[3.125rem]"
              >
                Get Started
                <span className="inline-flex w-[2.125rem] h-[2.125rem] bg-primary justify-center items-center rounded-full mr-auto md:mr-0">
                  <img
                    src={ArrowWhite}
                    alt=""
                  />
                </span>
              </Link>
            </div>

            <div className="py-12 px-[3.625rem] border-2 border-[#F8F7F3] rounded-[1.25rem] bg-[#F8F7F3]">
              <span className="inline-block italic py-2 px-[1.125rem] md:px-6 rounded-[6.25rem] bg-[#E8E3FF] text-xs md:text-sm lg:text-base mb-10 md:mb-[2.875rem] font-gorditamedium leading-[1.125rem] md:leading-[1.813rem]">Hire at a fixed monthly rate</span>
              <div className="font-gorditamedium text-xl lg:text-2xl mb-[0.625rem]"><span className="text-[2.875rem] lg:text-[3.375rem]">$2200</span>/Month</div>
              <span className="font-gorditamedium inline-block text-sm text-white bg-black italic py-1 pl-[0.813rem] pr-[1.063rem] rounded-[6.25rem] mb-[5.375rem] leading-[1.813rem]">~ Save 10%</span>
              <ul className="text-base text-black leading-[1.625] font-gorditaregular text-left">
                <li className="relative pl-7 mb-[0.625rem]">
                  <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-4 w-4 absolute top-[0.188rem] left-0"></span> Dedicated Pay only for hours worked
                </li>
                <li className="relative pl-7">
                  <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-4 w-4 absolute top-[0.188rem] left-0"></span> 8+ years of Ideal if looking for time and material model
                </li>
              </ul>
              <Link
                to=""
                className="inline-flex w-60 sm:w-auto gap-5 items-center bg-white-200 justify-center md:justify-start inline-white text-black border border-white shadow-1xl text-base rounded-3xl pl-7 py-1 pr-[0.313rem] font-gorditamedium hover:bg-primary hover:border-primary hover:text-white mt-[3.125rem]"
              >
                Get Started
                <span className="inline-flex w-[2.125rem] h-[2.125rem] bg-primary justify-center items-center rounded-full mr-auto md:mr-0">
                  <img
                    src={ArrowWhite}
                    alt=""
                  />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-8 lg:pb-10 xl:pb-20 lg:pt-[3.438rem] testimonial-sec md:py-[1.875rem]">
        <div className="container">
          <div className="w-full text-center lg:pb-10 pb-9">
            <div className="title-label text-center"><span className="font-gorditamedium inline-block bg-cream italic py-[0.563rem] px-[1.5rem] rounded-[6.25rem] mb-[0.625rem]">According to Clutch.co</span></div>
            <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium lg:max-w-[47rem] my-0 mx-auto">
              Techuz is one of the  <span className="italic">best development and IT firm</span> in the world
              <span className="inline-block text-orangecol">.</span>
            </h2>
            <p className="text-base lg:text-[1.125rem] lg:leading-[1.875rem]">And here’s what our clients say about us</p>
          </div>
          <SwiperSlider>
            <SwiperSlide>
              <div className="m-auto max-w-[65rem] w-full border border-[#C3DBE3] rounded">
                <div>
                  <div className="title-sec w-full relative border-b border-[#C3DBE3] py-6 px-5 flex flex-wrap items-center justify-between">
                    <h2 className="w-[16.25rem] max-[991px]:w-[80%] lg:w-2/3 text-2xl leading-normal font-gorditaregular pr-5">
                      Website Revamp for Sales & Marketing Software
                    </h2>
                    <span className="share icon flex flex-wrap items-center justify-center">
                      <StaticImage
                        src="../assets/img/share.webp"
                        alt="Awarded as top web developers in India 2019."
                        placeholder="blurred"
                      />
                      <Link to="" className="leading-normal ml-2 text-[#08537E] underline">Share</Link>
                    </span>
                  </div>

                  <div className="review-slider-content grid lg:grid-cols-[265px_1fr_220px]">
                    <div className="review-item p-5 border-r border-r-[#C3DBE3] max-[767px]:border-r-0">
                      <h3 className=" text-sm leading-[1.125rem] text-[#17313B] font-gorditamedium mb-[0.625rem]">THE PROJECT</h3>
                      <div className="project-type pb-[0.625rem] flex flex-wrap items-start">
                        <StaticImage
                          src="../assets/img/development.webp"
                          alt="Awarded as top web developers in India 2019."
                          placeholder="blurred"
                          className="inline-block w-[0.875rem]"
                        />
                        <p className="text-[#6A797D] text-sm leading-[1.125rem] inline-block pl-[0.625rem] w-[calc(100%-40px)]">UX/UI Design, Web Design, Web Development</p>
                      </div>
                      <div className="project-price pb-[0.625rem]">
                        <StaticImage
                          src="../assets/img/price-tag.webp"
                          alt="Awarded as top web developers in India 2019."
                          placeholder="blurred"
                          className="inline-block"
                        />
                        <p className="text-[#6A797D] text-sm leading-[1.125rem] inline-block pl-[0.625rem]">Less than $10,000</p>
                      </div>
                      <div className="project-time">
                        <StaticImage
                          src="../assets/img/calendar.webp"
                          alt="Awarded as top web developers in India 2019."
                          placeholder="blurred"
                          className="inline-block"
                        />
                        <p className="text-[#6A797D] text-sm leading-[1.125rem] inline-block pl-[0.625rem]">Nov. 2021 - July 2022</p>
                      </div>
                      <h3 className="text-sm leading-[1.125rem] text-[#17313B] font-gorditamedium mb-[0.625rem] mt-7">PROJECT SUMMARY</h3>
                      <p className="text-[#6A797D] text-sm leading-[1.125rem] inline-block">Techuz revamped the site of a sales and marketing software company, ensuring the site would meet SaaS standards and improve overall UX. They identified the client's requirements and created design wireframes.</p>
                    </div>
                    <div className="review-item p-0 md:p-5 grid grid-cols-1  md:grid-cols-[190px_1fr] gap-5 items-start border-r border-r-[#C3DBE3] max-[767px]:border-r-0">
                      <div className="review-rating md:text-center w-full md:w-[11.875rem] border border-[#C3DBE3] max-[767px]:border-l-0 max-[767px]:border-r-0 md:rounded p-5 md:px-[0.625rem] max-[767px]:pb-[30px]">
                        <span className="rating text-4xl text-[#17313B] leading-none font-gorditaregular max-[767px]:inline-block max-[767px]:align-middle max-[767px]:pr-[0.25rem] w-full inline-block">5.0</span>
                        <StaticImage
                          src="../assets/img/rating-stars.webp"
                          alt="Awarded as top web developers in India 2019."
                          placeholder="blurred"
                          className="mx-auto my-0 max-[767px]:m-0 max-[767px]:inline-block "
                        />
                        <ul className=" mt-[1.125rem] md:mt-[1.875rem] max-[767px]:grid max-[767px]:grid-cols-2 ">
                          <li className=" border-t border-t-[#C3DBE3] pt-[0.625rem] pb-[0.375rem] flex flex-wrap items-center justify-between max-[767px]:border-r max-[767px]:border-r-[#C3DBE3] max-[767px]:pr-[0.563rem]"><span className="inline-block text-sm text-[#17313B]">Quality</span><span className="inline-block text-sm text-[#17313B]"> 5.0</span></li>
                          <li className=" border-t border-t-[#C3DBE3] pt-[0.625rem] pb-[0.375rem] flex flex-wrap items-center justify-between max-[767px]:pl-[0.625rem]"><span className="inline-block text-sm text-[#17313B]">Schedule</span><span className="inline-block text-sm text-[#17313B]"> 5.0</span></li>
                          <li className=" border-t border-t-[#C3DBE3] pt-[0.625rem] pb-[0.375rem] flex flex-wrap items-center justify-between max-[767px]:border-r max-[767px]:pb-0 max-[767px]:border-r-[#C3DBE3] max-[767px]:pr-[0.563rem]"><span className="inline-block text-sm text-[#17313B]">Cost</span><span className="inline-block text-sm text-[#17313B]"> 5.0</span></li>
                          <li className=" border-t border-t-[#C3DBE3] pt-[0.625rem] pb-0 flex flex-wrap items-center justify-between max-[767px]:pl-[0.625rem]"><span className="inline-block text-sm text-[#17313B]">Willing to Refer</span><span className="inline-block text-sm text-[#17313B]"> 5.0</span></li>
                        </ul>
                      </div>
                      <div className="review-desc px-5 md:px-0">
                        <h3 className=" text-sm leading-[1.125rem] text-[#17313B] font-gorditamedium mb-[0.625rem]">THE REVIEW</h3>
                        <p className="text-[#6A797D] text-lg leading-[1.625rem] inline-block">"They understood our requirements and what we were trying to achieve."</p>
                        <p className="date text-sm leading-[1.125rem] text-[#17313B] my-5">Feb 2, 2023</p>
                        <h3 className=" text-sm leading-[1.125rem] text-[#17313B] font-gorditamedium mb-[0.625rem]">FEEDBACK SUMMARY</h3>
                        <p className="text-[#6A797D] text-sm leading-[1.125rem] inline-block">The website revamped by Techuz received positive responses. There were a few bugs and minor fixes to the website, which were resolved quickly. Throughout the engagement, the team communicated via WhatsApp and sent video recordings to the client to teach them how to make website edits on their own.</p>
                      </div>
                    </div>
                    <div className="reviewer-detail py-5 px-[1.063rem]">
                      <h3 className="text-sm leading-[1.125rem] text-[#17313B] font-gorditamedium mb-[0.625rem]">THE REVIEWER</h3>
                      <p className="text-[0.938rem] text-[#6A797D] leading-6 ">Head of Digital Marketing, Sales & Marketing Software</p>
                      <div className="reviewer-profile my-5">
                        <div className="img-container w-10 h-10 inline-block align-middle">
                          <StaticImage
                            src="../assets/img/anonymous.webp"
                            alt="Awarded as top web developers in India 2019."
                            placeholder="blurred"
                            className=" w-full h-full object-cover"
                          />
                        </div>
                        <p className="text-[#6A797D] text-sm leading-[1.125rem] inline-block w-[calc(100%-40px)] pl-[0.625rem] align-middle">Anonymous</p>
                      </div>
                      <ul className="user-details">
                        <li className="relative pl-5">
                          <StaticImage
                            src="../assets/img/software.webp"
                            alt="Awarded as top web developers in India 2019."
                            placeholder="blurred"
                            className="w-[0.75rem] h-[0.875rem] absolute left-0 top-1"
                          />
                          <p className="text-[#6A797D] text-sm leading-[1.125rem] inline-block">Software</p>
                        </li>
                        <li className="relative pl-5">
                          <StaticImage
                            src="../assets/img/location-pin.webp"
                            alt="Awarded as top web developers in India 2019."
                            placeholder="blurred"
                            className="w-[0.75rem] h-[0.875rem] absolute left-0 top-1"
                          />
                          <p className="text-[#6A797D] text-sm leading-[1.125rem] inline-block">Ahmedabad, India</p>
                        </li>
                        <li className="relative pl-5">
                          <StaticImage
                            src="../assets/img/user-icon.webp"
                            alt="Awarded as top web developers in India 2019."
                            placeholder="blurred"
                            className="w-[0.875rem] h-[0.875rem] absolute left-0 top-1"
                          />
                          <p className="text-[#6A797D] text-sm leading-[1.125rem] inline-block">1-10 Employees</p>
                        </li>
                        <li className="relative pl-5">
                          <StaticImage
                            src="../assets/img/phone.webp"
                            alt="Awarded as top web developers in India 2019."
                            placeholder="blurred"
                            className="absolute left-0 top-1"
                          />
                          <p className="text-[#6A797D] text-sm leading-[1.125rem] inline-block">Phone Interview</p>
                        </li>
                        <li className="relative pl-5">
                          <StaticImage
                            src="../assets/img/verified.webp"
                            alt="Awarded as top web developers in India 2019."
                            placeholder="blurred"
                            className="absolute left-0 top-1"
                          />
                          <p className="text-[#6A797D] text-sm leading-[1.125rem] inline-block">Verified</p>
                        </li>
                      </ul>
                    </div>
                  </div>


                </div>
              </div>
            </SwiperSlide>
          </SwiperSlider>
        </div>
      </section >

      <section className="hiring-section bg-gradient-to-b from-[#F8F7F3] to-[rgba(248, 247, 243, 0.00)] lg:pb-20 lg:pt-[6.875rem]">
        <div className="container">
          <div className="title-wrap">
            <h2 className="text-black text-center text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium lg:max-w-[47rem] my-0 mx-auto">
              Here’s how  <span className="italic">hiring with Techuz</span> gives you the advantage you need
              <span className="inline-block text-orangecol">.</span>
            </h2>
          </div>
          <p className="usecase-title text-center text-2xl font-gorditamedium leading-[1.45] pt-12 pb-10">Traditional Hiring VS Hiring with Techuz</p>
          <div className="hiring-flow">
            <div className="flow-title text-center">
              <div className="shadow-1xl font-gorditaregular text-lg text-black rounded-full bg-white leading-[1.875rem] py-[0.875rem] px-[2.125rem] inline-block">Sourcing Developers</div>
            </div>
            <div className="flow-content">
              <span className="flow-label inline-block border border-black rounded-[3.688rem] py-[0.625rem] px-[1.563rem] text-sm leading-none">5 days</span>
            </div>
          </div>
        </div>
      </section>
      {/* <end /> */}
      <Faq faqData={{}} />
      <PPCFooter showCorporateLocs hrsOfWorkDelv="1,30,000+" reviews="32" />
    </>
  )
}
export const Head = () => <Seo title="Hire React Developers USA" noIndex />

export default HireReactDevelopersUSA



